import Splide from '@splidejs/splide';
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
document.addEventListener('DOMContentLoaded', function () {

    var posts_carousel = new Splide( '#posts_carousel', {
        type: 'loop',
        autoScroll: {
            speed: 0.7,
        },
        drag: 'free',
        autoWidth: true,
        arrows : false,
        pagination: false,
        trimSpace: false,
        focus: 'center',
        mediaQuery: 'min',
        breakpoints: {
            0: {
                gap: '23px',
            },
            640: {
                gap: '38px',
            }
        }
    } );

    posts_carousel.mount({ AutoScroll });

});
